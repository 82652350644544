<template>
  <b-row>
    <b-col
      md="12"
    >
      <div class="font-weight-bold title d-sm-none h4 text-center">
        {{ title }}
      </div>
    </b-col>

    <b-col
      md="12"
    >
      <b-card>
        <div class="card-h d-flex flex-sm-column flex-md-row justify-content-between  align-items-center">
          <div class="title d-none d-sm-block">
            {{ title }}
          </div>
          <slot name="title" />
        </div>
        <div>
          <slot />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'EmptyCard',
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    table: {
      type: [Boolean, Number],
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.card-h {
  padding-bottom: 21.5px;
  border-bottom: 2px solid var(--seperator-color);
  .title {
    font-size: 25px;
    line-height: 1.2;
    color: var(--purple);
    font-weight: 700;
  }
}
</style>

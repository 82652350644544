<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('notifications.title')"
      :config="config"
    >
      <template
        #title-column="{props}"
      >
        {{ getText(props.row) }}
      </template>
      <template
        #message-column="{props}"
      >
        {{ getText(props.row, 'message') }}
      </template>
      <template
        #read_at-column="{props}"
      >
        <b-badge
          class="my-0"
          :variant="props.row.read_at?'primary':'warning'"
        >
          {{ props.row.read_at?props.row.read_at:$t('notifications.unread') }}
        </b-badge>
      </template>
      <template
        #actions-column="{props}"
      >
        <div class="d-flex">
          <b-button
            size="sm"
            class="btn-icon"
            variant="success"
            @click="markAsRead(props)"
          >
            <FeatherIcon icon="CheckIcon" />
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            class="btn-icon"
            style="margin-right: 5px"
            @click="handleDelete(props)"
          >
            <FeatherIcon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </ok-table>
  </div>
</template>

<script>
import { BBadge, BButton } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import NotificationsApi from '@/api/notifications'
import NotificationsTableConfig from './config/notifications-table-config'

export default {
  components: {
    OkTable,
    BBadge,
    BButton,
  },
  data() {
    return {
      config: NotificationsTableConfig,
    }
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    async markAsRead(props) {
      await this.$store.dispatch('auth/Mark_NOTIFICATION_READ', props.row.id)
      this.refreshTable()
    },
    getText(row, attr = 'title') {
      if (!row.translate) return row[attr]
      if (attr !== 'message' || !row.attributes) return this.$t(`${row[attr]}`)
      return this.$t(`${row[attr]}`, row.attributes)
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => NotificationsApi.delete(data.row.id)
          .then(response => {
            this.$notifySuccess('تمت عملية الحذف بنجاح')
            this.refreshTable()
            this.$store.commit('auth/SET_NOTIFICATIONS', response.notifications)
            this.$store.commit('auth/SET_NOTIFICATIONS_TOTAL', response.total)
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ok-table',{ref:"datatable",attrs:{"title":_vm.$t('notifications.title'),"config":_vm.config},scopedSlots:_vm._u([{key:"title-column",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.getText(props.row))+" ")]}},{key:"message-column",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.getText(props.row, 'message'))+" ")]}},{key:"read_at-column",fn:function(ref){
var props = ref.props;
return [_c('b-badge',{staticClass:"my-0",attrs:{"variant":props.row.read_at?'primary':'warning'}},[_vm._v(" "+_vm._s(props.row.read_at?props.row.read_at:_vm.$t('notifications.unread'))+" ")])]}},{key:"actions-column",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.markAsRead(props)}}},[_c('FeatherIcon',{attrs:{"icon":"CheckIcon"}})],1),_c('b-button',{staticClass:"btn-icon",staticStyle:{"margin-right":"5px"},attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.handleDelete(props)}}},[_c('FeatherIcon',{attrs:{"icon":"TrashIcon"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'

const NotificationsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/notifications`,
  rowsPath: 'notifications',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'notifications.notification-title',
      field: 'title',
    },
    {
      label: 'notifications.message',
      field: 'message',
    },
    {
      label: 'notifications.read-at',
      field: 'read_at',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
    {
      label: 'forms.actions',
      field: 'actions',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
  ],
  filters: [
  ],
}

export default NotificationsTableConfig
